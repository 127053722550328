import { PageProps } from 'gatsby';

import useEpdCover from '@hooks/useEpdCover';
import { PersonalDetailsPage } from '@src/modules/PersonalDetails/PersonalDetailsPage';
import { useCustomMixpanel } from '@services/Mixpanel';

const EpdCoverPersonalDetails = (props: PageProps) => {
  const mixpanel = useCustomMixpanel();

  useEpdCover();

  return <PersonalDetailsPage {...props} mixpanel={mixpanel} />;
};

export default EpdCoverPersonalDetails;
